@import "../../../../../styles/media";

.categoryContainer {
  width: 70%;
  height: max-content;
  background-color: white;
  color: black;
  border-radius: 24px;
  box-shadow: 0px 1px 10px #3e1a501a;
  padding: 30px;
  margin: auto;
  position: relative;
  margin-top: 3rem;

  @include for-phone-only {
    width: 90%;
    height: max-content;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;

    @include for-phone-only {
      font-size: 14px;
    }
  }

  .categoryList {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .categoryListElement {
      list-style: none;
      padding: 6px 19px;
      border-radius: 30px;
      border: 1px solid #b18bfd;
      border-radius: 30px;
      margin: 6px;
      color: #b18bfd;
      font-size: 13px;
      font-weight: 400;

      @include for-phone-only {
        font-size: 11px;
        padding: 5px 17px;
      }
    }
  }
}
