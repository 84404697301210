.innerWrapper {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  .heading {
    color: #908ca8;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 16px 0px 8px;
    text-align: center;
  }
  .description {
    color: #908ca8;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 2rem;
    text-align: center;
  }
  .button {
    background: var(--primaryBackground3);
  }
}
