@import '../../../styles/media';

@keyframes bganimation {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.container{
  @include for-phone-only{
    width: 90%;
  }
}

.nav_cont {
  width: 75%;
  margin: auto;
  background: #343a40;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;

  .btn-app {
    color: #fff;
    background-repeat: no-repeat;
    background-size: 800% 400%;
    background-attachment: fixed;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#1deab9, endColorstr=#ff2d60);
    background-image: linear-gradient(120deg, #1deab9, #29ccff, #6caafd, #ff61fa, #f2663d, #ff2d60);
    animation-name: bganimation;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: running;
    height: 30px;
    line-height: 1;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 15px;
  }


}

.footer_cont {
  margin-top: 50px;
  background: #212529;
  color: #fff;
  font-size: 0.8rem;
  padding: 30px 0;

  .sub_footer {
    width: 75%;
    margin: auto;
  }

  .text_orange_light {
    color: #f85c37;
  }
}
