@import "../../../styles/mixins";
@import "../../../styles/media";

.exploreWrapper {
  .logo {
    display: none;
  }
  @include for-phone-only {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 4rem);

    .logo {
      display: block;
      margin: 1rem 0.5rem 0;
    }
  }
  .contentWrapper {
    padding-top: 2rem;
    @include for-phone-only {
      background-image: linear-gradient(45deg, #ffffff, #fff6fb, #f5f1ff);
      flex: 1;
      margin-top: -1rem;
      padding-top: 4rem;
      border-radius: 1rem 1rem 0 0;
    }

    .back_button {
      height: 41px;
      width: 41px;
      border-radius: 50%;
      background-color: rgba(129, 94, 242, 16%);
      @include justify-align-center;
      position: absolute;
      left: 100px;
      cursor: pointer;
      z-index: 2;
      @include for-desktop-up {
        left: 100px;
      }
      @include for-phone-only {
        left: 26px;
        top: 145px;
      }
      @include for-tablet-landscape-max-min {
        left: 25px;
      }
    }
  }
}

.tabsList {
  display: flex;
  justify-content: center;
  color: #7f5cf1;
  @include for-phone-only {
    margin-left: -1rem;
    @include hideScrollBar;
    justify-content: flex-start;
    overflow: scroll;

    > li {
      padding: 1rem;

      &::before {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.tabsListContent {
  @include hideScrollBar;

  .loaderWrapper {
    position: relative;
    background-color: transparent;
    width: 100%;
    height: 500px;
  }
}
