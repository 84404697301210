@import "../../../styles/mixins.scss";
@import "../../../styles/media";

.back_button {
  height: 41px;
  width: 41px;
  border-radius: 50%;
  background-color: rgba(129, 94, 242, 16%);
  @include justify-align-center;
  position: absolute;
  left: 100px;
  cursor: pointer;
  z-index: 2;
  @include for-desktop-up {
    left: 100px;
  }
  @include for-phone-only {
    left: 26px;
    top: 145px;
  }
  @include for-tablet-landscape-max-min {
    left: 25px;
  }
}
