@import "../../../../../../styles/mixins.scss";

.roomCardSkeleton {
  height: 18rem;
  border-radius: 0.675rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.03);

  .roomInfo {
    @include justify-spaceBetween;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0.5rem;

    .userStatsWrapper {
      @include align-center;
      gap: 0.2rem;
    }
  }
}
