@import "../../../../../styles/media";

.aboutContainer {
  width: 70%;
  height: max-content;
  background-color: white;
  color: black;
  border-radius: 24px;
  box-shadow: 0px 1px 10px #3e1a501a;
  padding: 30px;
  margin: auto;
  position: relative;
  margin-top: 3rem;

  @include for-phone-only {
    width: 90%;
    height: max-content;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    @include for-phone-only {
      font-size: 14px;
    }
  }

  p {
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0px;

    @include for-phone-only {
      font-size: 11px;
    }
  }
  .listWrapper {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;

    .listContentWrapper {
      width: 158px;
      height: 60px;
      list-style: none;
      margin: 10px 10px 0px 0px;
      padding: 10px;
      background-color: #ece4ff;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      align-self: flex-start;
      overflow: hidden;

      .textWrapper {
        position: relative;
        z-index: 1;
        margin-left: 10px;
        align-self: center;
        font-weight: 500;
        color: #7f5cf1;

        h3 {
          font-weight: 400;
          font-size: 14px;

          @include for-phone-only {
            font-size: 12px;
          }
        }

        p {
          font-size: 10px;
          margin: 0px;

          @include for-phone-only {
            font-size: 8px;
          }
        }
      }

      .circleContainer {
        width: 200px;
        height: 151px;
        background-color: #cbb8ff;
        opacity: 20%;
        border-radius: 50%;
        position: absolute;
        top: 33px;
        align-self: center;
        z-index: 0;
      }
    }
  }
}
