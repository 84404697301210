.skeleton {
  width: 100%;
  height: 100%;
  background-color: rgba(199, 199, 199, 0.5);

  &.title {
    width: 100%;
    height: 1rem;
    margin: 0.5rem 0;
    border-radius: 0.2rem;
  }
  &.avatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100px;
  }
  &.label {
    width: 2rem;
    height: 1rem;
    border-radius: 6rem;
  }

  /* animation */
  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }

  &.animate {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}
