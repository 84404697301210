@import "../../../../styles/mixins.scss";
@import "../../../../styles/media";

.tabs {
  &List {
    display: flex;
    align-items: center;
    &Item {
      list-style: none;
      position: relative;
      font-size: 1rem;
      font-weight: 400;
      padding: 1rem 2rem;
      cursor: pointer;
      user-select: none;
      opacity: 0.5;
      transition: all 0.3s;

      &Active {
        opacity: 1;
        font-weight: 500;
        &::before {
          content: "";
          width: 40%;
          height: 2px;
          background: linear-gradient(
            115.14deg,
            #d45bff 16.73%,
            #f45698 57.26%,
            #dda36a 96.95%
          );
          position: absolute;
          bottom: 0;
          left: 30%;
        }
      }
    }
  }

  &Content {
    margin-top: 2rem;
  }
}

.tabsContent {
  padding: 0 15px;
}

.filterWraper {
  position: static;
  overflow-x: auto;
  padding: 0 10px;

  @include for-phone-only {
    max-width: 100%;
    height: 54px;
    margin: 0px auto 16px;
  }

  .filterContainner {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: max-content;
    margin: 0 auto;

    .filterIcon {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }

    .FilterOptions {
      display: flex;
      gap: 8px;
      @include for-phone-only {
        @include justify-align-center;
      }

      .Option {
        padding: 6px 14px;
        border-radius: 30px;
        font-size: 12px;
        line-height: 18px;
        color: #815ef2;
        background-color: #ffffff;
        border-image: linear-gradient(#b18bfd, #6846eb);
        border: 1px solid;
        cursor: pointer;
        &.Selected {
          background-image: linear-gradient(#b18bfd, #6846eb);
          color: #ffffff;
          border: 1px solid #b18bfd;
        }
      }
    }
  }
}
