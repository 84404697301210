@import "../../../../../styles/media";

.roomSkeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  position: relative;

  @include for-phone-only {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}
