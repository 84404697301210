// @import "../../../../styles/media";
@import "../../../../../styles/media";
@import "../../../../../styles/mixins.scss";

.nameContainer {
  width: 70%;
  background-color: white;
  color: black;
  border-radius: 24px;
  box-shadow: 0px 1px 10px #3e1a501a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  margin: auto;
  position: relative;
  margin-top: 7rem;

  @include for-phone-only {
    width: 90%;
    height: 230px;
    padding: 20px;
  }

  .callButtons {
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;

    @include for-phone-only {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 20px;
      background: #fff;
      z-index: 2;
      top: unset;
    }
  }

  .callBtn {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;
    //width: 48%;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    color: white;
    outline: none;
    //aspect-ratio: 2.9;
    height: 76px; //76px;
    padding: 11.5px 32px;
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 94px;
    width: fit-content;
    background: linear-gradient(
      99.24deg,
      #7166f9 0%,
      #9686fc 51.61%,
      #84c4ff 102.15%
    );
    .callIcon {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
      img {
        margin-right: 0.3rem;
        @include for-xl-only {
          width: 20px;
        }
        @include for-phone-only {
          width: 11px;
        }
      }

      @include for-phone-only {
        font-size: 10px;
      }
    }
    .priceSection {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 10px;
      gap: 4px;
      @include for-phone-only {
        justify-content: center;
        img {
          width: 8px;
          height: 8px;
        }
        font-size: 6px;
      }
    }

    @include for-xl-only {
      min-width: 134px;
      height: 62px;
      font-size: 16px;
    }

    @include for-phone-only {
      min-width: 90px;
      height: unset;
      font-size: 10px;
      padding: 11.5px 23px;
      width: 100%;
      flex: 1;
    }

    &:nth-child(1) {
      background: linear-gradient(135deg, #fca311 0%, #fc4911 100%);
    }
  }

  .userImageWrapper {
    width: 120px;
    height: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    z-index: 0;
    margin-top: -4rem;

    @include for-phone-only {
      margin-top: -3rem;
    }
    .userImageContainer {
      height: 120px;
      width: 120px;
      border: 3px solid #6846eb;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .userImage {
        border-radius: 50%;
        width: 114px;
        height: 114px;
      }
    }

    .badge {
      align-self: center;
      position: relative;
      bottom: 23%;
    }

    .userLevel {
      align-self: center;
      margin: auto;
      position: relative;
      bottom: 46%;
      font-size: 10px;
      font-weight: 700;
      color: #4c2d5a;
    }
  }
  .userNameWrapper {
    margin: auto;
    position: relative;
    z-index: 1;

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      font-size: 18px;
      font-weight: 500;
      img {
        margin-left: 0.5rem;
      }

      @include for-phone-only {
        font-size: 16px;
      }
    }
    ul {
      display: flex;
      justify-content: space-around;
      margin: 2px 0px 5px;
      li {
        list-style: none;
        margin: 0px 10px;
        font-weight: 400;
        font-size: 14px;

        @include for-phone-only {
          font-size: 11px;
        }
      }
      li:last-child {
        list-style: disc;
      }
    }

    p {
      height: 20px;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include for-phone-only {
        font-size: 11px;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }
  .viewersWrapper {
    background-color: #f3ebff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 64px;
    align-items: center;
    border-radius: 20px;
    position: relative;

    @include for-phone-only {
      grid-template-rows: 55px;
    }
    li {
      list-style: none;
      border-right: 1.5px solid #9e79f933;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include for-phone-only {
        font-size: 11px;
      }

      span {
        margin-right: 5px;
        font-weight: 600;
        font-size: 16px;

        @include for-phone-only {
          font-size: 13px;
        }
      }
    }

    li:last-child {
      border-right: none;
    }
  }
}
.popup_styles {
  background: linear-gradient(
    129.84deg,
    rgba(0, 0, 0, 0.6) 1.69%,
    rgba(0, 0, 0, 0.6) 103.1%
  );
  z-index: 2;
}

.popup_image_container {
  background-color: #4c2d5a;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-size: contain;
  object-fit: fill;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup_image {
    object-fit: contain;
    @include for-phone-only {
      width: 100vw;
      height: 100vw;
    }
  }
}
.image_popup_close {
  position: fixed;
  top: 60px;
  right: 65px;
  color: #4c2d5a;
  cursor: pointer;
  @include for-phone-only {
    top: 35px;
    right: 40px;
  }
}
