@import "@/modules/Astro/styles.module";
@mixin screen-background {
  background: var(--primaryBackground2);
  color: var(--primary);
  width: 100vw;
  min-height: 100vh;

  &::before {
    content: "";
    background: var(--primaryBackground2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  > div {
    position: relative;
  }
}

@mixin rainbowGradientBackground {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 20px rgba(226, 76, 105, 0.15);
  background: linear-gradient(#1a1222, #1a1222) padding-box,
    linear-gradient(#d55cff, #fa50b6, #e29573) border-box;
}

@mixin justify-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin justify-center {
  display: flex;
  justify-content: center;
}

@mixin justify-spaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin align-center {
  display: flex;
  align-items: center;
}

@mixin align-center-justify-spaceBetween {
  @include justify-spaceBetween;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin font-size-height-weight($size, $height, $weight) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
}
@mixin hideScrollbars {
  -ms-overflow-style: none; // IE 10+
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin align-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin justify-column-spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin coverBackgroundWithImage($image) {
  background: url($image);
  // @include crossBrowserBackgroundSize(cover)
  background-repeat: no-repeat;
}

@mixin crossBrowserBackgroundSize($backgroundSize) {
  -webkit-background-size: $backgroundSize;
  -moz-background-size: $backgroundSize;
  -o-background-size: $backgroundSize;
  background-size: $backgroundSize;
}

@mixin gradientBackground {
  background: linear-gradient(180deg, #100c15 0%, #191422 83.8%);
  backdrop-filter: blur(16px);
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
}

/* hide scrollbar but allow scrolling */
@mixin hideScrollBar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

// would be moving these variables to a variable file
$background_relative_path_earning: "/assets/backgrounds/Earning_bg.svg";
$background_relative_path_wallet: "/assets/backgrounds/Wallet_bg.svg";
$background_relative_path_backgroundInfoWrapper: "https://v2.chingari.io/earnings/Background/walletInfoWrapper.svg";
$background_image_position_earning: top 3px left 14px;
